
import { Injectable } from '@angular/core';
import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';
import * as moment from 'moment-timezone';
import * as momentTimeZone from 'moment-timezone';
@Injectable({
  providedIn: 'root',
})
export class AxiosInterceptorService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = this.createAxiosInstance();
    this.setupInterceptors();
  }

  private createAxiosInstance(): AxiosInstance {
    // Create Axios instance with custom settings if needed
    return axios.create();
  }

  private setupInterceptors(): void {
    // Bind the context of the interceptor functions to the current instance
  const requestInterceptor = this.requestInterceptor.bind(this);
  const responseInterceptor = this.responseInterceptor.bind(this);
  const errorInterceptor = this.errorInterceptor.bind(this);
    // Add a request interceptor
  this.axiosInstance.interceptors.request.use(
    requestInterceptor,
    errorInterceptor
  );

    // Add a response interceptor
  this.axiosInstance.interceptors.response.use(
    responseInterceptor,
    errorInterceptor
  );
  }

  private requestInterceptor(config: AxiosRequestConfig): AxiosRequestConfig {
    // Handle setting headers from local storage
    
    // Get the user's time zone
    const userTimeZone = moment.tz.guess();
   
// Use Intl.DateTimeFormat to get the full time zone name
const date = new Date();
const timeZoneName = new Intl.DateTimeFormat('en-US', {
    timeZone: userTimeZone,
    timeZoneName: 'long'
}).formatToParts(date).find(part => part.type === 'timeZoneName').value;
    try {
      const selectedCompanyBranch = JSON.parse(localStorage.getItem("selectedCompanyBranch") || '{}');
      const selectedUserObj = JSON.parse(localStorage.getItem("user") || '{}');
      if (selectedCompanyBranch && selectedUserObj) {
        config.headers['CompanyId'] = selectedCompanyBranch.companyId;
        config.headers['CompanyBranchId'] = selectedCompanyBranch.companyBranchId;
        config.headers['UserId'] = selectedUserObj.userId;
        config.headers['UserDateTime'] = new Date();
        config.headers['UserTimeZone'] = userTimeZone;
        config.headers['timeZoneName'] = timeZoneName;
      }
    } catch (error) {
      console.error('Error parsing local storage items', error);
    }
    return config;
  }

  private responseInterceptor(response: AxiosResponse): any {
    // Handle response data
    return response;
  }

  private errorInterceptor(error: AxiosError): Promise<any> {
    // Handle errors
    console.error('Axios request error: ', error);
    return Promise.reject(error);
  }

  // Example API methods
  public get(endpoint: string): Promise<any> {
    return this.axiosInstance.get(endpoint);
  }

  public post(endpoint: string, data: any, customHeader?: any): Promise<any> {
    return this.axiosInstance.post(endpoint, data, customHeader);
  }

  public put(endpoint: string, data: any): Promise<any> {
    return this.axiosInstance.put(endpoint, data);
  }

  public patch(endpoint: string, data: any): Promise<any> {
    return this.axiosInstance.patch(endpoint, data);
  }

  public delete(endpoint: string): Promise<any> {
    return this.axiosInstance.delete(endpoint);
  }

  // Add more methods for other HTTP verbs or custom API calls
}
